import React, { useState } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql, StaticQuery } from "gatsby";
import styled from "styled-components";
import { RichText } from "prismic-reactjs";
import { Grid, Row, Col } from "react-styled-flexboxgrid";
import { useRecoilValue } from "recoil";

import { browserState } from "../components/new/ScrollAware";
import Layout from "../components/new/Layout";
import HeroBlock from "../components/new/blocks/HeroBlock";
import FixedBlock from "../components/new/blocks/FixedBlock";
import QuoteBlock from "../components/new/blocks/QuoteBlock";
import CaseStudy from "../components/new/CaseStudy";

import { extractSocialMetadata } from "../utils/content";
import theme from "../theme/";

const Content = styled.div`
  background-color: ${props => props.theme.colors.grey04};
`;

const CaseStudiesContainer = styled.section`
  position: relative;
  background-color: ${props => props.theme.colors.grey04};
  padding-top: 100px;
  padding-bottom: ${props => props.theme.spacing.xxl};
  z-index: ${theme.zIndex.section};
`;

const CaseStudyContainer = styled(Col)`
  margin-bottom: 48px;
`;

const WorkPage = ({ data }) => {
  const { isIE } = useRecoilValue(browserState);
  const content = data.prismic.case_study_landing;
  const meta = data.site.siteMetadata;

  if (!content) {
    return null;
  }

  const featuredCaseStudies = content.featured_case_studies;

  const [hasSocialMetaData, socialMetadata] = extractSocialMetadata(
    content.body1
  );

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
        title={content.meta_title}
        meta={[
          {
            name: `description`,
            content: content.meta_description,
          },
          {
            property: `og:title`,
            content: hasSocialMetaData
              ? socialMetadata.general_card.title
              : meta.title,
          },
          {
            property: `og:description`,
            content: hasSocialMetaData
              ? socialMetadata.general_card.description
              : content.meta_description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: hasSocialMetaData && socialMetadata.general_card.image,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: meta.author,
          },
          {
            name: `twitter:site`,
            content: hasSocialMetaData && socialMetadata.twitter_handle,
          },
          {
            name: `twitter:title`,
            content: hasSocialMetaData
              ? socialMetadata.twitter_card.title
              : meta.title,
          },
          {
            name: `twitter:description`,
            content: hasSocialMetaData
              ? socialMetadata.twitter_card.description
              : meta.description,
          },
          {
            name: `twitter:image`,
            content: hasSocialMetaData && socialMetadata.twitter_card.image,
          },
        ].concat(meta)}
      />

      <Layout>
        <Content>
          <FixedBlock
            index={isIE ? theme.zIndex.background : theme.zIndex.swipe}
          >
            <HeroBlock
              image={content.case_study_landing_hero_image}
              title={content.case_study_landing_hero_headline}
              grey={true}
            />
          </FixedBlock>

          {content.featured_case_studies &&
            content.featured_case_studies.length && (
              <CaseStudiesContainer>
                <Grid>
                  <Row>
                    {content.featured_case_studies
                      .filter(c => c.featured_case_study)
                      .map((caseStudy, i) => {
                        const {
                          recommended_block_thumbnail,
                          client_name,
                          landing_page_impact_statement,
                          body,
                          _meta,
                        } = caseStudy.featured_case_study;
                        const contentTextBlock =
                          body &&
                          body.find(
                            slice => slice.type === "overview_text_block"
                          );
                        const { agency_name } = contentTextBlock.primary;

                        return (
                          <CaseStudyContainer xs={12} md={6} key={i} mb={2}>
                            <CaseStudy
                              image={recommended_block_thumbnail}
                              target={`/work/${_meta.uid}`}
                              heading={landing_page_impact_statement}
                              client={client_name}
                              agency={agency_name}
                            />
                          </CaseStudyContainer>
                        );
                      })}
                  </Row>
                </Grid>
              </CaseStudiesContainer>
            )}
        </Content>

        {data.prismic.case_study_landing.body.map((section, i) => {
          const { type, primary: data } = section;

          if (type === "quotation_feature_block") {
            const {
              quotation_block_quote,
              quotation_block_source,
              quotation_block_cta_copy,
              quotation_block_image,
            } = data.about_quotation_feature;

            return (
              <QuoteBlock
                key={`${type}_${i}`}
                quote={quotation_block_quote}
                author={quotation_block_source}
                cta={quotation_block_cta_copy}
                image={quotation_block_image}
              />
            );
          }
        })}
      </Layout>
    </>
  );
};

WorkPage.propTypes = {
  data: PropTypes.object.isRequired,
};

const query = graphql`
  {
    prismic {
      case_study_landing(lang: "en-gb", uid: "work") {
        meta_description
        meta_title
        case_study_landing_hero_headline
        case_study_landing_hero_image
        featured_case_studies {
          featured_case_study {
            _linkType
            ... on PRISMIC_Case_study {
              client_name
              case_study_hero_headline
              recommended_block_thumbnail
              landing_page_impact_statement
              _meta {
                uid
              }
              body {
                ... on PRISMIC_Case_studyBodyOverview_text_block {
                  type
                  label
                  primary {
                    agency_name
                  }
                }
              }
            }
          }
        }
        body {
          ... on PRISMIC_Case_study_landingBodyQuotation_feature_block {
            type
            label
            primary {
              quotation_feature_show_background_shape
              about_quotation_feature {
                ... on PRISMIC_Quotation_feature_block {
                  quotation_block_quote
                  quotation_block_source
                  quotation_block_cta_copy
                  quotation_block_image {
                    ... on PRISMIC__ImageLink {
                      _linkType
                      url
                    }
                  }
                }
              }
            }
          }
        }
        body1 {
          ... on PRISMIC_Case_study_landingBody1General_card {
            type
            label
            primary {
              description
              image
              title
            }
          }
          ... on PRISMIC_Case_study_landingBody1Twitter_card {
            type
            label
            primary {
              card_type
              description
              image
              title
              twitter_handle
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;

const WorkData = () => (
  <StaticQuery query={`${query}`} render={data => <WorkPage data={data} />} />
);

export default WorkData;
