import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "gatsby";
import Heading from "./typography/Heading";

const Container = styled.div`
  position: relative;
  height: 100%;
  background: ${props => props.theme.colors.white};
  padding-bottom: ${props => props.theme.spacing.xl};
  display: flex;
  flex-direction: column;
`;

const Cover = styled.div`
  background-image: ${props => `url(${props.image})`};
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-position: center;
  min-height: 200px;
  margin-bottom: ${props => props.theme.spacing.xxl};

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    min-height: 400px;
  }
`;

const StyledLink = styled(Link)`
  display: block;
  position: relative;
  height: 100%;
  transition: box-shadow 100ms ease-in-out;

  &:hover {
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.225);

    h2 {
      text-decoration: underline;
    }
  }
`;

const AgencyName = styled.p`
  position: relative;
  padding-left: ${props => props.theme.spacing.l};
  margin: ${props => props.theme.spacing.xl} ${props => props.theme.spacing.xl}
    0;
  color: ${props => props.theme.colors.grey01};
  display: flex;
  flex: 1;
  align-items: flex-end;

  strong {
    padding-right: 55px;
  }

  &::before {
    display: inline-block;
    content: "";
    left: -15px;
    bottom: 2px;
    height: 21px;
    width: 3px;
    transform: skew(-33deg);
    background-color: ${props => props.theme.colors.turquoise};
    overflow: hidden;
    margin-right: ${props => props.theme.spacing.l};
  }
`;

const CaseStudy = ({ image, target, client, agency, heading }) => (
  <StyledLink to={target}>
    <Container>
      <Cover image={image.url} />
      <Heading margin="0 xl" size="s" as="h4" grey>
        {client}
      </Heading>
      <Heading margin="0 xl" size="l" as="h2">
        {heading}
      </Heading>
      {agency && (
        <AgencyName>
          <strong>Agency:</strong>&nbsp;{agency}
        </AgencyName>
      )}
    </Container>
  </StyledLink>
);

CaseStudy.propTypes = {};

export default CaseStudy;
